<template>
    <table>
        <thead>
            <tr>
                <TableHeader v-for="(item, index) in headrData" :key="index"
                :headerName="item"></TableHeader>
            </tr>
        </thead>
        <tbody>
            <tr class="use-hover-tr" v-for="(item, index) in bodyData" :key="index" @click="goToDetails(bodyIds[index])" :data-id="bodyIds[index]">
                <TableBody v-for="(body, index) in item" :key="index" :bodyName="body"></TableBody>
            </tr>
        </tbody>
    </table>
</template>

<script>
import TableHeader from '@/components/base/TableHeader.vue';
import TableBody from '@/components/base/TableBody.vue';
import { useRouter } from 'vue-router';

export default {
  name: 'DeviceTable',
  components:{
    TableHeader,
    TableBody,
  },
  props: {
    headrData: Array,
    bodyData: Array,
    bodyIds: Array
  },
  setup() {
    const router = useRouter();

    const goToDetails = (id) => {
      router.push({ name: 'device-detail', params: { custDeviceId:id } });
    };

    return {
      goToDetails
    };
  }
}
</script>

<style scoped>
table{
  border: 0.5px solid var(--deep-blue);
  background-color: var(--white);
  padding: 0px;
  margin: auto;
  max-width: 1200px;
}

table thead{
  background-color: var(--deep-blue);
}
</style>