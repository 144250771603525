<template>
  <div class="base-block">
    <div class="d-flex">
        <TextInput
        divClass="form-block d-flex"
        inputId="name"
        inputClass="col-7"
        inputName=""
        inputType="text"
        labelClass="label-class col-5"
        labelName="氏名"
        size=""
        v-model="custUserData.name"
        :error="''"
      />
    </div>

    <div class="d-flex">
        <TextInput
          divClass="form-block d-flex"
          inputId="email"
          inputClass="col-7"
          inputName=""
          inputType="text"
          labelClass="label-class col-5"
          labelName="メールアドレス"
          size=""
          v-model="custUserData.email"
          :error="''"
        />
    </div>
  </div>

  <div class="d-none" id="remaind-block">
    <CheckInput
          divClass=""
          inputId="remaindEmail"
          inputClass=""
          inputName=""
          labelClass=""
          labelId=""
          labelName="メールアドレス変更"
          size=""
          labelColor=""
          v-model="custUserData.remaindEmail"
          @click="clickRemaindEmail()"
      />
  </div>

  <!-- 無効化できるユーザーに制限入れるかもなので分けている -->
  <div class="regist-block">
    <CheckInput
          divClass=""
          inputId=""
          inputClass=""
          inputName=""
          labelClass="label-class"
          labelId=""
          labelName="無効化"
          size=""
          labelColor=""
          v-model:modelValue="custUserData.registType"
      />
  </div>

    <div class="user-edit-btn btn-group">
      <ButtonBase color="red-magenta" btnClass=""  @click="saveCustUser">{{ saveBtnString }}</ButtonBase>
      <ButtonBase color="cyan" btnClass="" @click="closeModal" >戻る</ButtonBase>
    </div>
  </template>

  <script>
  import ButtonBase from '@/components/base/ButtonBase.vue';
  import TextInput from '../base/TextInput.vue';
  import CheckInput from '../base/CheckInput.vue';
  import {axios, apiHeader, apiUrl} from '@/constants.js';

  export default {
    name: 'UserEdit',
    components: {
      ButtonBase,
      TextInput,
      CheckInput
    },
    props: {
      isVisible:{
          type: Boolean,
          default: false
      },
      custUserId: Number
    },
    data(){
      return{
        custUserData:{},
        baseEmail:null,
        saveBtnString: '登録'
      }
    },
    mounted(){
      this.getUserData()
    },
    methods:{
      closeModal(){
        this.$emit('update:isVisible', false)
      },
      // ユーザーの情報取得
      async getUserData(){
        try {
          // 非同期処理を行う例（APIからデータを取得する場合など）
          const response = await axios.post(`${apiUrl}/cust-user-edit`, {
            custUserId: this.custUserId
          },
          {
            headers:apiHeader
          }
          );
          // 成功ならトークン保存して遷移
          if (response.status == 200){
            if(response.data.result.custUserData.id != null){
              this.saveBtnString = '更新'
              this.custUserData.remaindEmail = true
              this.clickRemaindEmail();
            }
            // 新規作成なら再送を非表示にする
            this.custUserData = response.data.result.custUserData;
            this.baseEmail = this.custUserData.email
            if(this.custUserData.regist_type != undefined){
              document.getElementById('remaind-block').classList.remove('d-none');
            }
          }
        }
        catch (error) {
          console.error('Error fetching data:', error);
          alert('エラーが発生しました')
        }
      },
      // ユーザー設定の登録,更新
      async saveCustUser(){
        try {
          // メール変更があるかどうかの確認
          if(!this.checkChangeEmail()){
            alert('メールアドレスを変更する場合は、メール再送にチェックを入れてください。');
            return false;
          }
          // 非同期処理を行う例（APIからデータを取得する場合など）
          const response = await axios.post(`${apiUrl}/save-cust-user`, {
            custUserData: this.custUserData
          },
          {
            headers:apiHeader
          }
          );
          // 成功ならトークン保存して遷移
          if (response.status == 200){
            alert('登録に成功しました');
            window.location.reload();
          }
        }
        catch (error) {
          console.error('Error fetching data:', error);
          alert('エラーが発生しました')
        }
      },
      // メアド変更の際のチェック
      checkChangeEmail(){
        if(this.custUserData.id != null && this.baseEmail != null && this.custUserData.email != this.baseEmail && !this.custUserData.remaindEmail){
          return false
        }
        return true
      },
      clickRemaindEmail(){
        if(this.custUserData.remaindEmail){
          document.getElementById('email').readOnly = true;
          document.getElementById('email').classList.add('readonly-color');
          return
        }
        document.getElementById('email').readOnly = false;
        document.getElementById('email').classList.remove('readonly-color');
        this.custUserData.email = this.baseEmail
      }
    }
  }
  </script>

<style scoped>
.form-block{
  width: 100%;
  margin-top: 1rem;
}
#remaind-block{
  margin-top: 1rem;
}
.regist-block{
  margin-top: 0.5rem;
}
.btn-group{
  margin-top: 1.5rem;
  margin-bottom: 10px;
}
</style>

<style>
  .base-block label{
    font-size: 1.05rem !important;
    margin: auto;
  }
  .regist-block label{
    font-size: 1.05rem !important;
  }
  .user-edit-btn button{
    margin-inline: 0.5rem;
  }

</style>