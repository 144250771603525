<template>
  <header class="loggedin-header">
    <div class="menu-toggle" @click="toggleMenu">
      <!-- ハンバーガーメニューのデザイン -->
      <span class="bar" v-bind:class="{ active: isActive }"></span>
      <span class="bar" v-bind:class="{ active: isActive }"></span>
      <span class="bar" v-bind:class="{ active: isActive }"></span>
    </div>
    <nav :class="{ active: isActive }" class="navbar d-flex">
      <h1 class="tittle-logo col-2">Smart IoT</h1>
      <nav class="nav-menu col-6">
          <a class="menu-link" id="menu-1" href="/"><strong>ダッシュボード</strong></a>
          <a class="menu-link" id="menu-2" href="/device-list"><strong>IoT端末</strong></a>
          <a class="menu-link" id="menu-3" href="/user-list"><strong>ユーザー</strong></a>
      </nav>
      <div class="login-info col-4">
          <span><span class="company-name" @click="clickEditBtn"> {{custName}} </span><strong>{{ userName }}</strong></span>
          <a class="logout-link " @click="clicklogout">Logout</a>
      </div>
    </nav>
  </header>
  <ModalBase v-model:isVisible="qrModal" contentStyle="max-width: 400px; max-height: 300px;">
    <SettingQR :custCd="custCd"/>
  </ModalBase>
</template>



<script>
import SettingQR from '../ui/SettingQR.vue';
import ModalBase from '../base/ModalBase.vue';
import {axios,apiUrl,custCd,apiHeader} from '@/constants.js';
import { useRouter } from 'vue-router';


export default {
  name: 'LoggedinHeader-2',
  props: {
    selectedMenu: Number
  },
  components:{
    SettingQR,
    ModalBase
  },
  data(){
    return {
      isActive: false,
      qrModal:false,
      custName: '',
      userName: '',
      custCd: custCd
    }
  },
  setup(){
    const router = useRouter();
    const redirectLogin = () => {
      // クエリパラメータを動的に追加
      router.push({ path: '/login', query: { cd: custCd } });
    };
    return {
      redirectLogin
    };
  },
  mounted(){
    const selected = document.getElementById('menu-'+String(this.selectedMenu));
    selected.classList.add('selected-menu');
    this.getHeaderData();
    resizeTo
  },
  methods: {
    toggleMenu() {
      this.isActive = !this.isActive;
    },
    clickEditBtn(){
        this.qrModal = true
    },
    clicklogout(){
      sessionStorage.removeItem('token');
      // サーバー側のトークンをリセット

      this.redirectLogin();
    },
    async getHeaderData(){
      try{
          const response = await axios.post(`${apiUrl}/header-data`,{}, {
            headers: apiHeader}
          )
          if (response.status == 200){
            this.userName = response.data.result["userName"]
            this.custName = response.data.result["customerName"]
          }
          else{
            alert(response.data.message);
            sessionStorage.removeItem('token');
            localStorage.removeItem('custCd');
            // とりあえず強制でログイン画面に
            this.redirectLogin()
          }
        }
        catch(error){
          console.error(error)
          // とりあえず強制でログイン画面に
          alert('エラーが発生しました。再度ログインしてください。')
          this.redirectLogin()
        }
    }
  }
}
</script>

<style scoped>
 .loggedin-header {
    background: linear-gradient(to right, var(--deep-blue), var(--cobalt-blue));
    color: var(--white);
    height: 4rem;
    margin-bottom: 1.5rem;
    z-index: 10000;
  }

  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.75rem;
  }

  /* ロゴ関連 */
  .tittle-logo {
    padding-left: 2rem;
    display: block;
    font-size: 1.8rem;
    color: var(--cyan);
    align-items: center;
    margin: 0px;
  }

  /* メニュー系 */
  .nav-menu {
    text-align: center;
    align-items: center;
  }

  /* ヘッダーのリンク関連 */
  .menu-link {
    color: var(--white);
    text-decoration: none;
    padding-inline: 0.7rem;
  }
  .menu-link:hover {
      text-decoration: underline;
  }
  .selected-menu{
    color: var(--aqua-blue) !important;
  }

  /* ハンバーガーメニュー関連 */
  .menu-toggle {
  display: none; /* デフォルトでは非表示 */
  flex-direction: column;
  cursor: pointer;
  padding-top: 1rem;
  margin-left: 1rem;
}

  .bar {
    height: 3px;
    width: 25px;
    background-color: white;
    margin-top: 3px;
  }

  /* ログイン情報 */
  .login-info {
    text-align: right;
    display: flex;
    align-items: center;
  }

  .company-name {
      font-size: 0.9rem;
      padding-right: 0.5rem;
  }
  .company-name:hover {
    color: var(--cyan);
    text-decoration: underline;
  }

  .logout-link{
    margin-left: 0.5rem;
    color: var(--aqua-blue);
    margin-right: 0.5rem;
  }

/* スマホ・タブレット用 */
@media (max-width: 813px) {
  .menu-toggle {
    display: flex; /* ハンバーガーメニューを表示 */
  }

  .loggedin-header {
    margin-bottom: 0.2rem;
  }

  .navbar {
    display: none; /* 初期状態では非表示 */
    flex-direction: column;
    width: 100%;
    background: linear-gradient(to right, var(--deep-blue), var(--cobalt-blue));
    position: absolute;
  }

  .navbar.active {
    display: flex; /* メニューが開かれたときに表示 */
  }

  .tittle-logo{
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0.5rem;
  }

  .nav-menu{
    margin-top: 1rem;
  }

  .menu-link{
    display: block;
    margin-bottom: 1rem;
  }

  .login-info{
    max-width: 100%;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    margin-inline: auto;
  }
}
</style>
