<template>
  <select @change="handleSelectionChange" :value="value">
    <option v-for="(item) in options" :key="item.id" :value="item.id">
          {{ item["name"] }}
        </option>
  </select>
  <p v-if="error" class="error-message">{{ error }}</p>
</template>

<script>
export default {
  name: 'SelectBase',
  props: {
    options:{
        type: Array,
        required: true
    },
    value:Number,
    error: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleSelectionChange(event) {
      const selectedValue =  Number(event.target.value);
      // 'update:selected' イベントを親コンポーネントに発火
      this.$emit('update:value', selectedValue);
    }
  },
    watch: {
      value(newVal) {
        this.localSelectedOption = newVal; // 親からの変更を監視
      }
    }
}
</script>

