<!-- CSS修正 -->
<template>
    <div class="login-body">
      <div class="login-container">
          <h1 class="">パスワードのリセット</h1>
          <form class="login-form">
            <div class="text-inputs">
                <TextInput
                divClass="form-group text-form"
                inputId="email"
                inputClass=""
                inputName="email"
                inputType="text"
                labelClass=""
                labelName="メールアドレス"
                size=""
                :error="emailError"
                v-model="email"
                />
            </div>
            <Button size="" color="cyan" id="login" class="submit-btn" @click="clickSend">仮パスワード送信</Button>
          </form>
      </div>
    </div>
  </template>

  <script>
  import Button from '@/components/base/ButtonBase.vue';
  import TextInput from '@/components/base/TextInput.vue';
  import {axios,apiUrl,apiHeaderNotLoginHeader} from '@/constants.js';
  import { useRoute, useRouter } from 'vue-router';
  import {erroResAlert,checkRequired} from '@/utils/utils.js'

  export default {
    name: 'CustLogin',
    components: {
      Button,
      TextInput
    },
    setup(){
      const route = useRoute();
      // 顧客判別用のコード取得
      const cd = route.query.cd;
      const router = useRouter();
      const redirectLogin = () => {
        // クエリパラメータを動的に追加
        router.push({ path: '/login', query: { cd: cd } });
      };
      return {
        redirectLogin,
        cd
      };
    },
    data() {
      return {
        email: '',
        emailError:'',
      };
    },
    methods:{
      // 仮パスワード送信クリック
      clickSend()
      {
        this.emailError='';
        const checkEmail = checkRequired(this.email,'メールアドレス');
        if (!checkEmail[0]) {
          this.emailError = checkEmail[1];
          return;
        }
        this.sendResetMail()
      },
      // ログイン処理
      async sendResetMail(){
        try {
          // 非同期処理を行う例（APIからデータを取得する場合など）
          const response = await axios.post(`${apiUrl}/password-reset`, {
              email:this.email,
              cd: this.cd
            },{
              // ログイン前はトークンなし
              headers:apiHeaderNotLoginHeader
            }
          );
          // 成功ならトークン保存して遷移
          if (response.status == 200){
            alert(response.data.message)
            this.redirectLogin();
          }
        } catch (error) {
          // 200以外ならメッセージ表示
          erroResAlert(error);
        }
      }
    }
  }
  </script>