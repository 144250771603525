<template>
  <div class="alert-base-input">
    <div class="d-flex">
      <TextInput
        divClass="d-flex form-block"
        inputId="name"
        inputClass="col-3"
        inputName=""
        inputType="text"
        labelClass="col-5"
        labelName="アラート名"
        size=""
        v-model="this.alertSettingData.name"
        :error="''"
      />
    </div>

    <div class="d-flex sensor-slect">
      <label class="col-5">センサー種別</label>
      <SelectBase :options="sensorTypeArray"
        @update:value="toggleVisInput"
        v-model:value="this.alertSettingData.sensorId"
        :value="this.alertSettingData.sensorId"
        class="col-3"
      />
    </div>

    <!-- ラベル名は別途修正 -->
   <div class="d-flex">
      <TextInput
        divClass="d-flex col-7 form-block"
        inputId="triggerfrom"
        inputClass="col-3"
        inputName=""
        inputType="number"
        labelClass="col-9"
        labelName="半径(km)"
        size=""
        v-model="this.alertSettingData.triggerFrom"
        :error="''"
      />
       <!--↓↓ここのinputはGPSの時は出さない↓↓-->
      <TextInput
      divClass="d-flex col-5 form-block"
      inputId="triggerAt"
      inputClass="gps-without-disp col-3"
      inputName=""
      inputType="number"
      labelClass="gps-without-disp col-2"
      labelName="  ～"
      size=""
      v-model="this.alertSettingData.triggerAt"
      :error="''"
      />
       <!--↑↑ここのinputはGPSの時は出さない↑↑-->
   </div>
  </div>

  <div class="alert-gps-input d-flex">
    <TextInput
      divClass="d-flex form-block"
      inputId="address"
      inputClass="gps-disp d-none col-5"
      inputName=""
      inputType="text"
      labelClass="gps-disp d-none col-5"
      labelName="住所"
      size=""
      v-model="address"
      :error="''"
    />
  </div>

  <ButtonBase color="cyan" btnClass="gps-disp d-none geocode-btn"  @click="getCoordinates">検索</ButtonBase>
  <input type="hidden" v-model="this.alertSettingData.baseLat">
  <input type="hidden" v-model="this.alertSettingData.baseLong">

  <div class="gps-disp d-none">
    <GoogleMap
      id="map"
      :center="center"
      :zoom="zoom"
      :api-key="googleMapsApiKey"
      :mapTypeControl=false
      :streetViewControl=false
      @click="clickMap"
    >
      <Marker :options="{ position: center }" />
      <Circle
      :options="{
        center:center,
        radius:this.alertSettingData.triggerFrom*100,
        fillColor: fillColor,
        strokeWeight: 0,
        }"/>
    </GoogleMap>
  </div>

  <!--↑↑ ここのinputはGPS以外の時は出さない ↑↑-->

  <div class="alert-base-input">

    <div class="d-flex">
      <label class="col-5">アラートの種類</label>
      <input type="radio"
              id="inout-false"
              :value="false"
              v-model="this.alertSettingData.isInout"/>
      <label for="inout-false">範囲外検知</label>
      <input type="radio"
              id="inout-true"
              :value="true"
              v-model="this.alertSettingData.isInout"/>
      <label for="inout-true">範囲内検知</label>
    </div>

    <div class="d-flex">
      <TextInput
        divClass="d-flex form-block"
        inputId="alertDelay"
        inputClass="col-3"
        inputName=""
        inputType="number"
        labelClass="col-5"
        labelName="アラート発報待機時間"
        size=""
        v-model="this.alertSettingData.alertDelay"
        :error="''"
      />
    </div>
    <div class="d-flex">
      <TextArea
        divClass="d-flex form-block"
        inputId="free1"
        inputClass="col-6"
        inputName=""
        inputType="text"
        labelClass="col-5"
        labelName="フリー入力欄1"
        size=""
        v-model="this.alertSettingData.free1"
        :error="''"
      >
      </TextArea>
    </div>
    <div class="d-flex">
      <TextArea
        divClass="d-flex form-block"
        inputId="free2"
        inputClass="col-6"
        inputName=""
        inputType="text"
        labelClass="col-5"
        labelName="フリー入力欄2"
        size=""
        v-model="this.alertSettingData.free2"
        :error="''"
      >
      </TextArea>
    </div>
    <div class="d-flex">
      <TextArea
        divClass="d-flex form-block"
        inputId="free3"
        inputClass="col-6"
        inputName=""
        inputType="text"
        labelClass="col-5"
        labelName="フリー入力欄3"
        size=""
        v-model="this.alertSettingData.free3"
        :error="''"
        >
        </TextArea>
    </div>
    <div class="d-flex form-block">
      <label class="col-5">利用状態</label>
      <input type="radio"
              id="isValid-true"
              :value="true"
              v-model="this.alertSettingData.isValid"/>
      <label for="isValid-true">利用</label>
      <input type="radio"
              id="isValid-false"
              :value="false"
              v-model="this.alertSettingData.isValid"/>
      <label for="isValid-false">無効</label>
    </div>
  </div>

  <div class="alert-edit-btn btn-group">
    <ButtonBase color="red-magenta" btnClass=""  @click="saveAlert">{{saveBtnString }}</ButtonBase>
    <ButtonBase color="cyan" btnClass="" @click="closeModal" >戻る</ButtonBase>
  </div>
</template>

<script>
import ButtonBase from '@/components/base/ButtonBase.vue';
import TextInput from '../base/TextInput.vue';
import SelectBase from '../base/SelectBase.vue';
import TextArea from '../base/TextArea.vue';
import { GoogleMap, Marker, Circle} from 'vue3-google-map';
import {axios, apiHeader, apiUrl, geocodingUrl, gpsSensorType} from '@/constants.js';

export default {
  name: 'AlertSetting',
  components: {
    ButtonBase,
    TextInput,
    SelectBase,
    GoogleMap,
    Marker,
    Circle,
    TextArea
  },
  props: {
    isVisible:{
        type: Boolean,
        default: false
    },
    custDeviceId: Number,
    alertSettingId: Number,
  },
  data(){
    return{
      alertSettingData:{},
      sensorTypeArray:[],
      address: "",
      googleMapsApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      center: { lat: 35.681236, lng: 139.767125 }, //現在地が分からないなら東京駅
      zoom: 16,
      saveBtnString:'登録',
      fillColor:'#FF6666'
    }
  },
  mounted(){
    this.getAlertSettingData();
  },
  methods:{
    closeModal(){
      this.$emit('update:isVisible', false)
    },
    // セレクトボックスの制御
    handleSelectionChange(newSelection) {
      this.sensorTypeArray.value = newSelection;
    },
    // アラート設定の情報取得
    async getAlertSettingData(){
      try {
        //  セレクトの値ごとの制御
        // 非同期処理を行う例（APIからデータを取得する場合など）
        const response = await axios.post(`${apiUrl}/alert-setting`, {
          custDeviceId: this.custDeviceId,
          alertSettingId: this.alertSettingId
        },
        {
          headers:apiHeader
        }
        );
        // 成功ならトークン保存して遷移
        if (response.status == 200){
          if(response.data.result.alertSettingData.alertSettingId != null){
            this.saveBtnString = '更新'
          }
          this.alertSettingData = response.data.result.alertSettingData;
          // 地図の中心の座標を設定
          this.setCenter();
          this.sensorTypeArray = response.data.result.sensorTypeArray
          this.toggleVisInput(this.alertSettingData.sensorId);
        }
      }
      catch (error) {
        console.error('Error fetching data:', error);
        alert('エラーが発生しました')
      }
    },
    // アラート設定の登録,更新
    async saveAlert(){
      try {
        this.modifyParam()
        // 非同期処理を行う例（APIからデータを取得する場合など）
        const response = await axios.post(`${apiUrl}/alert-save`, {
          alertSettingData: this.alertSettingData
        },
        {
          headers:apiHeader
        }
        );
        // 成功ならトークン保存して遷移
        if (response.status == 200){
          alert('登録に成功しました');
          window.location.reload();
        }
      }
      catch (error) {
        console.error('Error fetching data:', error);
        alert('エラーが発生しました')
      }
    },
    // アラートの種類ごとのinputの表示,非表示の制御
    toggleVisInput(newVal){
        const gps_disp = document.querySelectorAll('.gps-disp');
        const without_disp = document.querySelectorAll('.gps-without-disp');
        // GPS場合の処理
      if(newVal==gpsSensorType){
        gps_disp.forEach(element => {
            element.classList.remove('d-none');
        });
        without_disp.forEach(element => {
            element.classList.add('d-none');
        });
      }
      else{
        gps_disp.forEach(element => {
            element.classList.add('d-none');
        });
        without_disp.forEach(element => {
          element.classList.remove('d-none');
        });
      }
    },
    // 登録,更新処理前のデータ調整
    modifyParam() {
      // GPSなら緯度経度追加と値のToは削除
      if(this.alertSettingData.sensorId == gpsSensorType){
        this.alertSettingData.baseLong = this.center.lng;
        this.alertSettingData.baseLat = this.center.lat;
        this.alertSettingData.triggerAt = null;
      }else{
        // GPS以外なら緯度経度削除
        this.alertSettingData.baseLong = null
        this.alertSettingData.baseLat = null
      }
    },
    // 住所から座標取得
    async getCoordinates() {
      try {
        const response = await axios.get(geocodingUrl, {
          params: {
            address: this.address,
            key: this.googleMapsApiKey,
          }
        });
        if (response.data.status === 'OK') {
          const location = response.data.results[0].geometry.location;
          this.center =location;
          // inputに追加
          return location;
        }
        else {
          alert('住所の取得に失敗しました。');
          console.error('Error fetching coordinates:', response.data.status);
        }
      } catch (error) {
        console.error('Error fetching coordinates:', error);
        throw error;
      }
    },
    // 地図の中心の座標を設定
    setCenter(){
      const baseLat = this.alertSettingData.baseLat;
      const baseLong = this.alertSettingData.baseLong;
      if(baseLat!=0 && baseLat!=null && baseLong!=null && baseLong!= 0){
        this.center= {lat: baseLat, lng: baseLong}
      }
    },
    clickMap(event){
      const eventLat = event.latLng.lat();
      const eventLong = event.latLng.lng();
      if(eventLat!=0 && eventLat!=null && eventLong!=null && eventLong!= 0){
        this.center= {lat: eventLat, lng: eventLong}
      }
    }
  }
}

</script>

<style scoped>
  .btn-group{
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .sensor-slect{
    margin-top: 1rem;
  }

  .form-block{
  width: 100%;
  margin-top: 1rem;
}

  .geocode-btn{
    margin-top: 1rem;
    margin-bottom: 1.2rem;
  }

  .map-frame{
    margin: 1.5rem;
    min-height: 400px;
    min-width: 500px;
  }

  #map {
    width: 100%;
    height: 30Vh;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

</style>

<style>
  .alert-base-input label{
    font-size: 1.05rem !important;
  }

  .alert-gps-input label{
      font-size: 1.05rem !important;
    }

  .alert-edit-btn button{
      margin-inline: 0.5rem;
    }
</style>