<template>
    <div :class="['control-panel','dashbord-content']" style="">
            <P class="panel-logo"><strong>端末情報</strong></p>
            <div class="base-card">
              <form class="search-form">
                <div v-for="(device, index) in deviceList" :key="device">
                  <CheckInput
                    divClass=""
                    :inputId="`device-Count-${index}`"
                    inputClass=""
                    inputName=""
                    labelClass="device-label"
                    :labelId="`device-id-${index}`"
                    :labelName="device.name"
                    size=""
                    labelColor="deep-blue"
                    :modelValue="device.check"
                    @update:modelValue="notifyParent()"
                    v-model:modelValue="device.check"
                  >
                  </CheckInput>
              </div>
              <!-- <div class="search-items">
                <TextInput inputClass="search-input"></TextInput>
                <ButtonBase color="cyan" btnClass="search-btn">検索</ButtonBase>
              </div> -->
              </form>
            </div>
    </div>
</template>
<script>
  import CheckInput from '../base/CheckInput.vue';
  // import TextInput from '../base/TextInput.vue';
  // import ButtonBase from '../base/ButtonBase.vue';

  export default {
    name: 'ControlPanel',
    components:{
      CheckInput,
      // TextInput,
      // ButtonBase
    },
    props: {
      type: String,
      deviceList: Array
    },
    data(){
      return{
        deviceArray: this.deviceList
      }
    },
    methods:{
      // 親コンポーネントに変更を通知させる
      notifyParent(){
        this.$emit('update:deviceList',this.deviceList);
      }
    }
  }
</script>

<style scoped>
  .control-panel {
      width: 20%;
      background: linear-gradient(to bottom, var(--deep-blue) 80%, var(--midnight-blue) 100%);
      color: var(--white);
      box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
      margin-right: 0.3rem;
      border-radius: 8px;
      padding: 0px 0.6rem;
  }

  .panel-logo {
      color: var(--white);
      font-size: 1.8rem;
      margin: 20px 1rem;
      border-bottom: 5px solid var(--white);
  }
  .base-card{
    background-color: var(--white);
    border-radius: 8px;
    margin:auto;
    padding: 1rem;
    height: 83%;
  }

  /* 検索関連 */
  .search-form{
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .search-items{
    margin-top: 0.3rem;
    margin-top: auto;
  }
  .search-btn{
    margin-top: 0.3rem;
    margin-bottom: 0.5rem;
  }

  /* スマホ,タブレットの画面サイズ用 */
  @media (max-width: 813px) {
    .control-panel {
      width: 95%;
      margin-inline: auto;
    }
    .base-card{
      height: 13vh;
    }
    .dashbord-content{
        min-height: 70px;
        max-height: 400px;
        height: 30vh;
        margin-bottom: 1rem;
    }
  }
</style>