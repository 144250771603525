import { createRouter, createWebHistory } from 'vue-router';
import DashbordHome from '../views/Home.vue';
import DeviceList from '../views/DeviceList.vue';
import CustLogin from '../views/CustLogin.vue';
import UserList from '@/views/UserList.vue';
import DeviceDetail from '@/views/DeviceDetail.vue';
import CustDeviceSetting from '@/views/CustDeviceSetting.vue';
import CustUserRegist from '@/views/CustUserRegist.vue';
import SettingQR from '@/components/ui/SettingQR.vue';
import PasswordReset from '@/views/PasswordReset.vue';
import StockDeviceRegist from '@/views/StockDeviceRegist.vue';
import RdxLogin from '@/views/RdxLogin.vue';

const routes = [
  { path: '/', component: DashbordHome },
  { path: '/device-list', component: DeviceList },
  { path: '/login', name: 'tenant', component: CustLogin },
  { path: '/reset', name: 'reset', component: PasswordReset },
  { path: '/user-list', component: UserList },
  { path: '/device-detail/:custDeviceId', name: 'device-detail', component: DeviceDetail , props: true },
  { path: '/password-regist', name: 'definRegist', component: CustUserRegist,},
  {path: '/qr',name: 'SettingQR',component: SettingQR,},
  // 以下からはRadix側のみ利用
  // 顧客・端末紐づけ(スマホ)
  { path: '/custdevice-setting', name: 'CustDeviceSetting', component: CustDeviceSetting,},
  // 在庫端末画面ログイン(スマホ,PC)
  { path: '/rdx-login', name: 'RdxLogin', component: RdxLogin },
  // 在庫端末登録(スマホ)
  { path: '/stockdevice-regist', name: 'StockDeviceRegist', component: StockDeviceRegist,},
  // 在庫一覧（PC?)
  // 在庫編集（PC?)
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;