<template>
<div class="m-i-a m-t-1">
    <canvas id="photo-canvas" class="camera-block d-none" ref="canvas"></canvas>
    <img v-if="photo" :src="photo" alt="Captured Image" class="d-none" />
    <video id="photo-video" class="camera-block" ref="video" playsinline autoplay muted></video>
</div>
<div class="btn-block">
    <div id="start-btn" >
        <ButtonBase class="btn-pi-05 d-block m-i-a" @click="startCamera">撮影開始</ButtonBase>
    </div>
    <div id="take-btn">
        <ButtonBase class="btn-pi-05 d-block m-i-a m-t-05" @click="takePhoto">OCR撮影</ButtonBase>
    </div>
    <ButtonBase class="btn-pi-05 d-block m-i-a m-t-05" color="cyan" @click="searchDeviceData">端末情報チェック</ButtonBase>
</div>
</template>
<script>
import ButtonBase from '@/components/base/ButtonBase.vue';

export default {
    name: 'CameraOCR',
    components:{
        ButtonBase
    },
    setup(){
    },
    mounted(){
    },
    data(){
        return{
            photo: null
        }
    },
    methods:{
        async startCamera() {
            try{
                // モアレ対策の縦横調整
                this.resizeImgElement();
                const videoDevices = await navigator.mediaDevices.enumerateDevices();
                // バックカメラの存在チェック（ラベルに "back" を入れると背面?）
                const backCamera = videoDevices.find(device => device.kind === 'videoinput' && device.label.toLowerCase().includes('back')) ||
                                videoDevices[0];
                if (backCamera) {
                    const stream = await navigator.mediaDevices.getUserMedia({
                        // バックカメラを指定
                        video: {
                            facingMode: { exact: 'environment' }
                        }
                    });
                    // canvasを非表示
                    document.getElementById('photo-canvas').classList.add('d-none');
                    document.getElementById('start-btn').classList.add('d-none');
                    // imgファイルを消す
                    this.photo = null;
                    // video表示
                    document.getElementById('photo-video').classList.remove('d-none');
                    document.getElementById('take-btn').classList.remove('d-none');
                    const videoElement = document.getElementById('photo-video');
                    videoElement.srcObject = stream;
                }
                else {
                    alert('カメラが起動できません');
                }
            } catch (error) {
                alert('カメラが起動できません');
            }
        },
        // 縦横サイズ調整
        resizeImgElement (){
            this.videoWidth = window.innerWidth;
            this.videoHeight = window.innerHeight;
            if (this.$refs.video) {
                this.$refs.video.width = this.videoWidth * 0.8;
                this.$refs.video.height = this.videoHeight * 0.8;
                this.$refs.canvas.width = this.videoWidth * 0.8;
                this.$refs.canvas.height = this.videoHeight * 0.59;
            }
        },
        takePhoto() {
            const canvas = this.$refs.canvas;
            const video = this.$refs.video;
            const context = canvas.getContext('2d');
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            // videoを非表示にして、canvasを表示
            document.getElementById('photo-canvas').classList.remove('d-none');
            document.getElementById('start-btn').classList.remove('d-none');
            document.getElementById('photo-video').classList.add('d-none');
            document.getElementById('take-btn').classList.add('d-none');
            this.photo = canvas.toDataURL('image/jpeg',1.00);
        },
    }
}

</script>

<style scoped>
    
</style>