<!--
 利用方法
 <Button size=""
 btnClass=""
 id=""
 class=""></Button>
-->
<template>
    <button
    type="button"
        :id="btnId"
        :class="['btn',btnClass,sizeClass,colorClass]"
    >
        <slot></slot>
    </button>
</template>

<script>
export default{
    name: 'ButtonBase',
    props:{
        btnId: String,
        btnClass: String, // 色やサイズ以外のクラスを指定
        size: String,
        color: String
    },
    computed:{
        sizeClass(){
            return `btn--${this.size}`;
        },
        colorClass(){
            return `btn--${this.color}`;
        }
    }
}
</script>

<style scoped>
/* 共通スタイル */
    .btn{
        border: none;
        border-radius: 5px;
        font-size: 1rem;
    }

    /* 色関連のスタイル */
    /* cyan */
    .btn--cyan{
        background-color: var(--cyan);
        color: var(--white);
    }
    .btn--cyan:hover {
        background-color: var(--cobalt-blue);
    }

    .btn--deep-blue{
        background-color: var(--deep-blue);
        color: var(--white);
    }
    .btn--deep-blue:hover {
        background-color: var(--cobalt-blue);
    }

    .btn--red-magenta{
        background-color: var(--red-magenta);
        color: var(--white);
    }
    .btn--red-magenta:hover {
        background-color: var(--medium-violet);
    }

    /* 大きさ関連のスタイル */

</style>