<template>
    <td :class="['td-class',bodyClass]" :id="bodyId">
        {{ bodyName }}
    </td>
</template>

<script>

export default {
  name: 'TableBody',
  props: {
    bodyName: String,
    bodyClass: String,
    bodyId: String
  },
  methods:{
  }
}
</script>

<style scoped>
    .td-class{
        background-color: var(--alice-blue);
        color: var(--deep-blue);
        padding: 0.8rem 1rem;
        font-size: 1rem;
        border: 2px solid var(--white);
    }
</style>