<template>
    <div class="tab-container">
        <div v-for="(tabitem) in tabItems" :key="tabitem.id">
            <Button size="" color="deep-blue" :class="['tab-btn',]"
            :data-id="tabitem.id" @click="click_tab_btn" :id="`tabCount-${tabitem.id}`">
                {{tabitem.name}}
            </Button>
        </div>
    </div>
    <div class="tab-border"></div>
</template>

<script>
    import Button from '@/components/base/ButtonBase.vue';
    import { nextTick } from 'vue';

    export default {
        name: 'TabBase',
        components: {
            Button,
        },
        props: {
            tabList: Array
        },
        data(){
            return {
                tabItems: []
            }
        },
        mounted(){
            this.tabItems = this.tabList;
        },
        methods:{
            click_tab_btn(event){
                const id = event.target.dataset.id;
                this.$emit('updateState', id);
                const elements = document.querySelectorAll('.active-tab');
                elements.forEach(element => {
                    element.classList.remove('active-tab');
                });
                event.target.classList.add("active-tab")
            },
            setActiveTab(){
                const tabElements = document.querySelectorAll('.active-tab');
                if (this.tabItems.length != 0 && tabElements.length == 0) {
                    tabElements.forEach(element => {
                        element.classList.remove('active-tab');
                    });
                    document.getElementById('tabCount-1').classList.add("active-tab")
                }
            },
        },
        // タブリストの監視
        watch: {
            async tabList(newVal) {
                this.tabItems = newVal; // 親からの変更を監視
                await nextTick();
                this.setActiveTab();
            }
        }
    }
</script>

<style scoped>
 .tab-container{
    display: flex;
    padding: 0rem;
 }
 .tab-btn{
    padding: 0.5rem 1rem;
    margin-left: 0.5rem;
    border-bottom: 1px solid var(--deep-blue);
 }
 .active-tab{
    background-color: var(--cyan);
    border-bottom: 1px solid var(--yellow);
 }
 .tab-border{
    border-bottom: 3px solid var(--deep-blue);
    width: 99%;
    display: block;
    margin-bottom: 1rem;
    margin-inline: auto;
 }
</style>