<template>
    <LoggedinHeader :selectedMenu="selected"></LoggedinHeader>
    <div class="base-card">
      <slot></slot>
    </div>
</template>

<script>
import LoggedinHeader from '@/components/layout/LoggedinHeader.vue';

export default {
  name: 'LoggedinBase',
  components:{
    LoggedinHeader
  },
  props: {
    selected: Number
  },
}
</script>

<style scoped>
.base-card{
  padding: 1.5rem;
  background-color: var(--white);
  margin:auto;
  max-width: 1500px;
  border: 1px solid var(--silver);
  border-radius: 8px;
}
</style>