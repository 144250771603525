<template>
    <div class="base-block">
        <div class="base-card">
        <h2 class="title-text">在庫登録ページ</h2>

        <!-- OCRで自動選択? -->
        <div class="m-t-05">
            <label class=" m-r-1">型番</label>
            <SelectBase :options="typeSelectList"
                v-model:value="typeId"
                :value="typeId"
                class=""
                :error="typeIdError"
            />
        </div>

        <TextInput
            divClass="m-t-05"
            inputId=""
            inputClass=""
            inputName=""
            inputType=""
            labelClass=""
            labelName="lfourd ID"
            size=""
            v-model="lfourdId"
            :error="lfourdIdError"
        />

        <TextInput
            divClass="m-t-05"
            inputId=""
            inputClass=""
            inputName=""
            inputType=""
            labelClass=""
            labelName="サービスタグ"
            size=""
            v-model="serviceTag"
            :error="serviceTagError"
        />

        <div class="m-t-05">
            <label class=" m-r-1">通信プラン</label>
            <SelectBase :options="planSelectList"
            v-model:value="planId"
            :value="planId"
            class=""
            :error="planIdError"
        />
        </div>
        <CameraOCR/>
        <div class="btn-block m-t-1">
            <ButtonBase color="red-magenta" class="btn-i-p" @click="registStockDevice">登録</ButtonBase>
        </div>
        </div>

    </div>
</template>
<script>
import {axios, apiRdxHeader, apiUrl} from '@/constants.js';
import {errorAlertAPI} from '@/utils/utils.js';
import TextInput from '@/components/base/TextInput.vue';
import ButtonBase from '@/components/base/ButtonBase.vue';
import SelectBase from '@/components/base/SelectBase.vue';
import CameraOCR from '@/components/layout/CameraOCR.vue';

  export default {
    name: 'StockDeviceEdit',
    components:{
        TextInput,
        ButtonBase,
        SelectBase,
        CameraOCR
    },
    mounted(){
        this.searchDeivceData();
    },
    data(){
        return{
            typeSelectList:[],
            planSelectList:[],
            typeId: null,
            planId: null,
            lfourdId: null,
            serviceTag: null,
            typeIdError: null,
            planIdError: null,
            lfourdIdError: null,
            serviceTagError: null
        }
    },
    methods:{
        // 登録画面の情報取得
        async searchDeivceData(){
            try {
                // 非同期処理を行う例（APIからデータを取得する場合など）
                const response = await axios.post(`${apiUrl}/device-types`, {},
                {
                    headers:apiRdxHeader
                }
                );
                // 成功ならトークン保存して遷移
                if (response.status == 200){
                    this.custData = response.data.result
                    this.typeSelectList = response.data.result.typeSelectList
                    this.planSelectList = response.data.result.planSelectList
                }
            }
            catch (error) {
                errorAlertAPI(error)
                // ログイン画面に戻す
            }
        },
        // 撮影ボタンクリック
        // 撮影
        // OCR
        // デバイスの登録
        async registStockDevice(){
            try {
                // 入力チェック
                if (this.typeIdError == null || this.planIdError == null || this.lfourdIdError == null ||this.serviceTag == null) {
                    this.typeIdError = this.checkInput(this.typeId,'型番')
                    this.planIdError = this.checkInput(this.planId,'通信プラン')
                    this.lfourdIdError = this.checkInput(this.lfourdId,'lfourd ID')
                    this.serviceTagError = this.checkInput(this.serviceTag,'サービスタグ')
                    return;
                }
                // 非同期処理を行う例（APIからデータを取得する場合など）
                const response = await axios.post(`${apiUrl}/regist-stock-device`, {
                    typeId: this.typeId,
                    planId: this.planId,
                    lfourdId: this.lfourdId,
                    serviceTag: this.serviceTag
                },
                {
                    headers:apiRdxHeader
                }
                );
                // 成功ならトークン保存して遷移
                if (response.status == 200){
                    console.log(response);
                    alert(response.data.message);
                    // 一覧に戻る方がいいかも
                    window.location.reload();
                }
            }
            catch (error) {
                console.error('Error fetching data:', error);
                alert('エラーが発生しました')
            }
        },
        // 入力チェック
        checkInput(val,labelName){
        let errorMes=''
        console.log(val)
        if (val == '' || val == null) {
            errorMes=labelName+'を入力してください'
            }
        return errorMes
        },
    }
  };
  </script>

<style scoped>
    .btn-block{
        margin-top: 1rem;
    }
    .btn-i-p{
        padding-inline: 0.5rem;
    }
    .m-r-1{
        margin-right: 1rem;
    }
    .m-t-05{
        margin-top: 0.5rem;
    }
    .title-text{
        color: var(--deep-blue);
    }
    .base-card{
        background-color: var(--white);
        border-radius: 8px;
        margin:auto;
        height: 80%;
        width: 80%;
        border: 0.2px solid var(--silver);
        padding-bottom: 3rem;
    }
</style>