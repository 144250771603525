<template>
    <th :class="['th-class',headerClass]" :id="headerId">
        {{ headerName }}
    </th>
</template>

<script>

export default {
  name: 'TableHeader',
  props: {
    headerName: String,
    headerClass: String,
    headerId: String
  },
  methods:{
  }
}
</script>

<style scoped>
    .th-class{
        background-color: var(--deep-blue);
        color: var(--white);
        padding: 0.8rem 1.2rem;
        font-size: 1.3rem;
    }
</style>