<template>
    <div v-if="isVisible" class="modal-overlay" @click.self="closeModal">
        <div class="modal-content" :style="contentStyle">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default{
    name:"ModalBase",
    props:{
        isVisible :{
            type: Boolean,
            default: false
        },
        contentStyle: String  //width,heightは親コンポーネントのスタイルが当たらなそうなので、ここで調整
    },
    methods:
    {
        closeModal(){
            this.$emit('update:isVisible', false)
        }
    }
}
</script>

<style>
.modal-content{
    background-color: var(--white);
    border-radius: 5px;
    border: 1px solid var(--silver);
    width: 80%;
    position: relative;
    padding: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}
</style>