<!--
    利用方法
    <CheckInput
            divClass=""
            inputId=""
            inputClass=""
            inputName=""
            labelClass=""
            labelId=""
            labelName=""
            size=""
            :error=""
            labelColor=""
    />
-->
<template>
    <div :class="divClass">
        <input type="checkbox" :id="inputId" :class="['check-input',size,inputClass]" 
        :name="inputName"
        v-model="isChecked" @change="$emit('update:modelValue', $event.target.checked)"
        >
        <label :id="labelId" :class="['label-input',labelClass,colorClass]" :for="inputId">
            {{labelName}}
        </label>
        <p v-if="error" class="error-message">{{ error }}</p>
    </div>
</template>

<script>
export default{
    name: 'CheckInput',
    props:{
        divClass: String,
        inputId: String,
        inputClass: String,
        inputName: String,
        inputType:String,
        readonly: {
            type: Boolean,
            default: false
        },
        labelId: String,
        labelClass: String,
        labelName: String,
        size: String,
        error: {
            type: String,
            default: '',
        },
        modelValue: Boolean,
        labelColor:String
    },
    data(){
        return{
            isChecked : this.modelValue
        }
    },
    computed:{
        sizeClass(){
            return `input--${this.size}`;
        },
        colorClass(){
            return `label--${this.labelColor}`;
        },
    },
    watch: {
        modelValue(newVal) {
            this.isChecked = newVal;
        },
    }
}
</script>

<style scoped>
    /* 共通スタイル */
    .check-input{
      display: inline;
      width: auto;
      border: 1px solid var(--silver);
    }
    .label-input{
      display: inline;
      padding-left: 0.2rem;
    }
    .error-message {
        color: red;
        font-size: 0.8em;
        margin-bottom: 0px;
        margin-top: 1px;
        padding-left: 1.25rem;
    }

    /* 色ごと */
    .label--deep-blue{
        color: var(--deep-blue);
    }
</style>