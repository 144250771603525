export const apiUrl = process.env.VUE_APP_API_URL;
export const apiHeader = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
  }
export const apiRdxHeader = {
  'Content-Type': 'application/json',
  'Rdx-Authorization': `${sessionStorage.getItem('rdx-token')}`
}
export const apiHeaderNotLoginHeader = {'Content-Type': 'application/json'}
export const axios = require('axios');
export const geocodingUrl = 'https://maps.googleapis.com/maps/api/geocode/json';
export const gpsSensorType = 1;
export const custCd = localStorage.getItem('custCd');
export const passwordMinLength = 8;