<template>
    <LoggedinBase :selected="2">
        <div class="device-detail-container">
            <div class="">
                <div class="title-block">
                    <h1 class="col-6 title">基本情報</h1>
                    <div class="btn-block col-6">
                        <ButtonBase color="cyan" btnClass="" @click="clickEditBtn">編集</ButtonBase>
                    </div>
                </div>
                <div class="edit-block">
                    <div class="device-info">
                        <label class="device-label col-6"><strong>端末名</strong></label>
                        <p class="device-data col-6">{{ custDeivceData.name }}</p>
                    </div>
                    <div class="device-info">
                        <label class="device-label col-6"><strong>型式</strong></label>
                        <p class="device-data col-6">{{ custDeivceData.type }}</p>
                    </div>
                    <div class="device-info">
                        <label class="device-label col-6"><strong>バッテリー</strong></label>
                        <p class="device-data col-6">{{ custDeivceData.battery }} %</p>
                    </div>
                    <div class="device-info">
                        <label class="device-label col-6"><strong>通信プラン</strong></label>
                        <p class="device-data col-6">{{ custDeivceData.plan_type_name }}</p>
                    </div>
                    <div class="device-info">
                        <label class="device-label col-6"><strong>利用開始日</strong></label>
                        <p class="device-data col-6">{{ custDeivceData.start_date }}</p>
                    </div>
                    <div class="device-info">
                        <label class="device-label col-6"><strong>フリー入力欄1</strong></label>
                        <p class="device-data col-6">{{ custDeivceData.free_info_1 }}</p>
                    </div>
                    <div class="device-info">
                        <label class="device-label col-6"><strong>フリー入力欄2</strong></label>
                        <p class="device-data col-6">{{ custDeivceData.free_info_2 }}</p>
                    </div>
                    <div class="device-info">
                        <label class="device-label col-6"><strong>フリー入力欄3</strong></label>
                        <p class="device-data col-6">{{ custDeivceData.free_info_3 }}</p>
                    </div>
                    <div class="device-info">
                        <label class="device-label col-6"><strong>備考</strong></label>
                        <p class="device-data col-6">{{ custDeivceData.note }}</p>
                    </div>
                </div>
            </div>
            <ModalBase v-model:isVisible="deviceModal">
                <DeviceEdit :custDeviceId="this.custDeivceData.id"
                :custDeviceName="this.custDeivceData.name"
                :custDeviceNote="this.custDeivceData.note"
                :custDeviceFree1="custDeivceData.free_info_1"
                :custDeviceFree2="custDeivceData.free_info_2"
                :custDeviceFree3="custDeivceData.free_info_3"
                v-model:isVisible="deviceModal"
                 />
            </ModalBase>
            <div class="">
                <h1 class="btn-block col-6">アラート履歴</h1>
                <AlertLogTable :headrData="alertLogHeader" :bodyData="alertLogData"></AlertLogTable>
            </div>
            <div class="">
                <div class="title-block">
                    <h1 class="col-6 title">アラート設定</h1>
                    <div class="col-6 btn-block">
                        <ButtonBase color="cyan" btnClass="" @click="clickAlertEditBtn">+</ButtonBase>
                    </div>
                </div>
                <AlertSettingTable v-model:isVisible="alertModal" :headrData="alertSettingHeader"
                :bodyData="alertSettingData" :bodyIds="alertSettingIds"
                v-model:alertSettingId="alertSettingId" />
                <ModalBase v-model:isVisible="alertModal" contentStyle="max-width: 700px; ">
                    <AlertSetting v-model:isVisible="alertModal"
                    :custDeviceId="this.custDeivceData.id" :alertSettingId="alertSettingId" />
                </ModalBase>
            </div>
        </div>
    </LoggedinBase>
</template>

<script>
import LoggedinBase from '@/components/layout/LoggedinBase.vue';
import AlertSettingTable from '@/components/ui/AlertSettingTable.vue';
import ButtonBase from '@/components/base/ButtonBase.vue';
import AlertLogTable from '@/components/ui/AlertLogTable.vue';
import DeviceEdit from '@/components/ui/DeviceEdit.vue';
import ModalBase from '@/components/base/ModalBase.vue';
import AlertSetting from '@/components/ui/AlertSetting.vue';
import { useRoute } from 'vue-router';
import {axios, apiHeader, apiUrl} from '@/constants.js';

export default {
  name: 'DeviceDetail',
  components: {
    LoggedinBase,
    AlertSettingTable,
    ButtonBase,
    AlertLogTable,
    DeviceEdit,
    ModalBase,
    AlertSetting,
  },
  data(){
    return {
        deviceModal:false,
        alertModal:false,
        custDeivceData: {},
        alertLogHeader:[],
        alertSettingHeader:[],
        alertLogData:[],
        alertSettingData:[],
        alertSettingId: null,
        alertSettingIds:[]
    }
  },
  props: {

  },
  mounted(){
    this.getCustDeivceData();
  },
    setup() {
        // 顧客端末のIDを取得
        const route = useRoute();
        const id = route.params.custDeviceId;
        return {id}
    },
  methods:{
    clickEditBtn(){
        this.deviceModal = true
    },
    clickEditBackBtn(){
        this.deviceModal = false
    },
    clickAlertEditBtn(){
        this.alertSettingId = null;
        this.alertModal = true;
    },
    clickAlertEditBackBtn(){
        this.alertModal = false
    },
    async getCustDeivceData(){
        try {
            // 非同期処理を行う例（APIからデータを取得する場合など）
            const response = await axios.post(`${apiUrl}/device-detail`, {
                deiceId:this.id
            },
            {
                headers:apiHeader
            }
            );
            // 成功ならトークン保存して遷移
            if (response.status == 200){
                const data = response.data.result
                this.alertLogHeader = data.alertLogHeader;
                this.alertSettingHeader = data.alertSettingHeader;
                this.alertLogData = data.alertLogData;
                this.alertSettingData = data.alertSettingData;
                this.custDeivceData = data.custDeivceData;
                this.alertSettingIds= data.alrertSettingIds;
            }
            } catch (error) {
                console.error('Error fetching data:', error);
                alert('エラーが発生しました')
        }
    }
  }
}
</script>

<style scoped>
.title-block{
    display: flex;
}
.title{
    margin-bottom: 0.5rem;
}
/*  */
.device-info{
    display: flex;
    margin: auto;
}
.device-label{
    margin-right: 1rem;
    text-align: right;
}
.device-data{
    margin: 0px;
    padding-left: 1rem;
    text-align: left;
}
/*  */
.btn-block{
    margin-top: auto;
    margin-bottom: auto;
}

.edit-block{
    display: block;
}
</style>