<template>
  <LoggedinBase :selected="3">
    <div class="user-list-container">
      <form>
        <div class="d-flex">
          <div class="col-9"></div>
          <ButtonBase class="add-block" color="cyan" btnClass="" @click="clickUSerEditBtn">
            +
          </ButtonBase>
        </div>
        <UserTable  v-model:isVisible="userModal" :headrData="headerList"
        :bodyData="userList" :bodyIds="bodyIds" v-model:custUserId="custUserId"></UserTable>
      </form>
    </div>
    <ModalBase v-model:isVisible="userModal" contentStyle="max-width: 400px; max-height: 200px;">
      <UserEdit v-model:isVisible="userModal" v-model:custUserId="custUserId"></UserEdit>
    </ModalBase>
  </LoggedinBase>
</template>

<script>
  import LoggedinBase from '@/components/layout/LoggedinBase.vue';
  import UserTable from '@/components/ui/UserTable.vue';
  import ButtonBase from '@/components/base/ButtonBase.vue';
  import UserEdit from '@/components/ui/UserEdit.vue';
  import ModalBase from '@/components/base/ModalBase.vue';
  import { ref } from 'vue';
  import {axios, apiHeader, apiUrl} from '@/constants.js';

  const sharedState = ref('Initial State');

export default {
  name: 'UserList',
  components: {
    LoggedinBase,
    UserTable,
    ButtonBase,
    UserEdit,
    ModalBase
  },
  data(){
    return {
      userList: [],
      headerList:[],
      bodyIds:[],
      userModal:false,
      custUserId:null
    }
  },
  mounted(){
    this.getUserList();
  },
  methods:{
      updateState(newState) {
        sharedState.value = newState;
      },
      clickUSerEditBtn(){
        this.custUserId = null
        this.userModal = true;
    },
      // 選択したタブで内容を変える
      async getUserList(){
      try{
          const response = await axios.post(`${apiUrl}/user-list`,{}, 
            {
              headers: apiHeader
            }
          )
          if (response.status == 200){
            this.userList = response.data.result['usersData'];
            this.headerList = response.data.result['headerData'];
            this.bodyIds = response.data.result['userIds'];
          }
          else{
            alert(response.data.message);
          }
        }
        catch(error){
          console.error(error)
          // とりあえず
          alert('エラーが発生しました。');
        }
    }
  }
}
</script>

<style>
.add-block{
  margin-bottom: 0.4rem;
}
</style>
