export function errorAlertAPI(error){
  if (error.response){
    alert(error.response.data.error)
  }
  else{
    alert('エラーが発生しました。')
  }
}

// エラー発生時のアラート
export function erroResAlert(error){
  // phpそのもののエラーメッセージは隠す
  if (error.response && !error.response.data.exception){
    alert(error.response.data.message)
  }
  else{
    alert('エラーが発生しました。')
  }
}

// 入力チェック
export function checkRequired(val,labelName){
  let errorMes=''
  if (val == '') {
      errorMes=labelName+'を入力してください'
      return [false,errorMes];
    }
  return [true,errorMes];
}

// 文字数チェック
export function checkLength(val,labelName,minLength){
  let errorMes='';
  if (val.length < minLength) {
    errorMes=`${labelName}は、${minLength}文字以上必要です。`
    return [false,errorMes];
  }
  return [true,errorMes];
}