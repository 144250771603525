<template>
  <div class="map-area">
  <GoogleMap
    class="dashbord-content"
    :center="center"
    :zoom="zoom"
    :api-key="googleMapsApiKey"
    :mapTypeControl=false
    :streetViewControl=false
  >
  <Polyline  v-for="(path, index) in paths" :key="index+ '_polyline'"
    :options="path"
    />
    <InfoWindow v-for="(marker, index) in markers" :key="index"
      :options="{position:marker.position, headerDisabled:true, opened:ture}"
        @click="onMarkerClick(marker.custDeviceId)"
    >
      <div class="marker-text" :style="'color:' + marker.textColor">
        {{ marker.name }}
      </div>
    </InfoWindow>
  </GoogleMap>
  </div>
  <ModalBase v-model:isVisible="isVisible" contentStyle="max-width: 400px; max-height: 800px;">
    <DeviceMqttDetail v-model:isVisible="isVisible" v-model:custDeviceId="custDeviceId"/>
  </ModalBase>
</template>

<script>
import { GoogleMap,Polyline,InfoWindow} from 'vue3-google-map';
import ModalBase from '@/components/base/ModalBase.vue';
import DeviceMqttDetail from './DeviceMqttDetail.vue';

export default {
  components: {
    GoogleMap,
    Polyline,
    InfoWindow,
    ModalBase,
    DeviceMqttDetail
  },
  props: {
    deviceList:Array,
    markerData:Array,
    polylineData:Array
  },
  data() {
    return {
      isVisible: false, //端末詳細のモーダルON,OFF用
      googleMapsApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      center: { lat: 35.681236, lng: 139.767125 }, //現在地が分からないなら東京駅
      zoom: 14,
      markers:[],
      paths: [],
      custDeviceId: null
    }
  },
  mounted(){
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.updatePosition);
    } else {
      alert('現在地の取得に失敗しました。');
    }
  },
  methods:{
    // マーカークリック時にモーダルを出す
    onMarkerClick(custDeviceId) {
      this.isVisible = true;
      this.custDeviceId = custDeviceId;
    },
    // 現在地で場所を地図の中心を更新
    updatePosition(position) {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    },
  },
  watch: {
    polylineData(newVal) {
      this.paths = newVal
    },
    markerData(newVal){
      this.markers=newVal
    }
  }
};
</script>

<style>
 #map {
    width: 100%;
  }
  .map-area {
    width: 80%;
    margin-left: 0.3rem;
  }

  .marker-text :hover{
    color: var('--cyan');
    margin-left: 0.3rem;
  }

  @media (max-width: 813px) {
    .map-area {
      width: 100%;
      margin-inline: auto;
    }
  }
</style>